/* eslint-disable import/prefer-default-export */
function toBoolean(value) {
  try {
    return eval(value);
  } catch (error) {
    console.error('toBoolean() conversion is failed');
    return Boolean(value);
  }
}

/**
 * Data la chiave viene restituito il valore del paramentro
 * in query string
 * @param {valore ricercato} param
 * @type {tipologia valore ricercato} type
 */
export function getUrlQueryParam(param, type) {
  const urlParams = new URLSearchParams(window.location.search);
  const valueParam = urlParams.get(param);
  switch (type) {
  case 'Boolean':
    return toBoolean(valueParam);
  default:
    return valueParam;
  }
}

/**
 * Dati url, chiave viene restituito il valore del paramentro
 * in query string
 * @param {stringa url} string
 * @param {valore ricercato} param
 * @type {tipologia valore ricercato} type
 */
export function getQueryParam(string, param, type) {
  const urlParams = new URLSearchParams(string);
  const valueParam = urlParams.get(param);
  switch (type) {
  case 'Boolean':
    return toBoolean(valueParam);
  default:
    return valueParam;
  }
}
