<template>
  <fragment>
    <header>
      <h1 class="logo">
        <a href="/">Sace</a>
      </h1>
    </header>
    <div
      v-if="landing"
      class="content"
    >
      <div class="actions">
        <div class="box">
          <h2>Accedi</h2>
          <p class="text-medium">
            Inserisce le tue credenziali per entrare nella tua area riservata.
          </p>
          <login-button
            :class="'button block primary'"
            :is-autenticated="isAuthenticated"
          />
          <p class="text-small">
            <a href="/login/reset">Hai dimenticato la password?</a>
          </p>
        </div>
        <div class="box">
          <h2>Registrati</h2>
          <p class="text-medium">
            Crea il tuo profilo SACE per partecipare ai nostri eventi e scoprire i nostri prodotti e
            servizi.
          </p>
          <button
            class="button block primary"
            @click="goToRegistrati"
          >
            Crea profilo
          </button>
        </div>
      </div>
      <div
        v-if="showInfoBox"
        class="info"
      >
        <p
          class="text-medium"
          v-html="infoBox.content"
        />
      </div>
    </div>
    <footer>
      <ul class="text-small">
        <li>
          <a
            href="https://www.sace.it/bandi-e-gare"
            target="_blank"
          >
            Bandi e gare
          </a>
        </li>
        <li>
          <a
            href="https://www.sace.it/reclami"
            target="_blank"
          >
            Reclami
          </a>
        </li>
        <li>
          <a
            href="https://www.sace.it/trasparenza"
            target="_blank"
          >
            Trasparenza
          </a>
        </li>
        <li>
          <a
            href="https://www.sace.it/note-legali"
            target="_blank"
          >
            Note legali
          </a>
        </li>
        <li>
          <a
            href="https://www.sace.it/privacy"
            target="_blank"
          >
            Privacy
          </a>
        </li>
      </ul>
      <p class="text-small">
        SACE S.p.A. Codice Fiscale e Partita IVA 05804521002
      </p>
    </footer>
  </fragment>
</template>

<script>
import Vue from 'vue';
import Fragment from 'vue-fragment';
import LoginButton from '../login-button.vue';

Vue.use(Fragment.Plugin);

export default {
  name: 'LandingBoard',
  components: {
    'login-button': LoginButton,
  },
  props: {
    landing: {
      type: Boolean,
      default: false,
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    registrazioneUrl: {
      type: String,
      default: '/Registrati',
    },
    infoBox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showInfoBox() {
      return this.infoBox.show;
    },
  },
  methods: {
    goToRegistrati() {
      window.location.href = this.registrazioneUrl;
    },
  },
};
</script>
