import Vue from 'vue';
import { getParamsFromDataAttributes, paramFromDataAttribute } from '../util/url/searchParams';
import Landingboard from '../Components/landing-registrazione/Landingboard.vue';
import { RecoMixin } from '../common/RecoMixin';

const es6Promise = require('es6-promise');

if (!global.Promise) {
  es6Promise.polyfill();
}

const v = new Vue({
  el: '#app',
  components: {
    'landing-board': Landingboard,
  },
  mixins: [RecoMixin],
  data: {
    modals: {
      error: false,
    },
    infoBox: {
      show: true,
      content: `Benvenuto nell’area riservata del Gruppo SACE.<br>
      All’interno troverai i nostri prodotti digitali, richiedibili e gestibili in autonomia 100% online e servizi e contenuti esclusivi a te dedicati.
      Se sei già registrato clicca su “accedi” e inserisci le tue credenziali, se invece non sei ancora registrato puoi farlo in pochi passi cliccando su “registrati”.`,
    },
    params: {
      p: null,
      c: null,
      ass: null,
      event: null,
      article: null,
      paese: null,
      project: null,
      type: null,
      content: null,
    },
  },
  computed: {
    isAuthenticated() {
      return window.isAuthenticated === 'true';
    },
    registratiUrl() {
      const searchParams = window.location.search;
      return `/Registrati${searchParams}`;
    },
    queryParamP() {
      return this.params.p;
    },
  },
  watch: {
    queryParamP: {
      handler(p) {
        if (p == 'calendarioEventi') {
          this.infoBox = {
            show: true,
            content: `Per partecipare all’evento, <strong>registrati</strong> all’area
            riservata di SACE.<br>Se hai già un account,
            <strong>accedi</strong> per proseguire.`,
          };
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.checkError();
    this.params = getParamsFromDataAttributes('main');
  },
  methods: {
    checkError() {
      const params = new URLSearchParams(window.location.search);
      const error = params.get('error');
      if (error === 'generic-error') {
        this.infoBox = {
          show: true,
          content: 'Errore riprovare più tardi',
        };
      } else if (error === 'no-user') {
        this.infoBox = {
          show: true,
          content: `I dati inseriti per effettuare il login al portale MySace non sono corretti.
          Prova ad effettuare il <a href="/logout">logout</a>, chiudi il browser e ripeti l'accesso in una nuova finestra.
          Se il problema persiste puoi contattare il Customer Care per verificare la tua utenza e richiedere l'abilitazione.`,
        };
      }
    },
  },
});
